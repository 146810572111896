import React from "react";
import {Box} from "@mui/material";
import {CardProps} from "../../interfaces";
import "./style.scss";
import {useSelector} from "react-redux";
import {getText} from "../../utils";

/**
 * Renders a card component with the given image source, number of trees, zone, and click event handler.
 *
 * @param imgSrc - The source of the image.
 * @param trees - The number of trees.
 * @param zone - The zone.
 * @param handleClick - The click event handler.
 * @return The rendered card component.
 */
const Cards = ({ imgSrc, trees, zone, handleClick }: CardProps) => {
  const language = useSelector((state:any)=>state.language);
const staticTexts = getText(language);
  return (
    <Box
      id="statistic-card"
      className="card-wrapper cursor-pointer"
      onClick={handleClick}
    >
      <div className="tree-image-container">
        <img className="tree-image" src={imgSrc} alt="tree-img" />
      </div>
      <p className="planted-tree-data font-md">{staticTexts['treesPlanted']} : {trees}</p>
      <Box className="zone">{zone}</Box>
    </Box>
  );
};

export default Cards;
