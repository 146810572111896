import {GET_LANGUAGE} from "../actions/action";

const initialState: any = 'kn'

/**
 * Returns the language reducer based on the given action type.
 *
 * @param state - The current state of the reducer.
 * @param action - The action object with type and payload properties.
 * @return The updated state based on the action type.
 */
const getLanguageReducer = (state = initialState, action: { type: string, payload: '' }) => {
    switch (action.type) {
        case GET_LANGUAGE: {
            return action.payload
        }
        default:
            return state
    }
};
export { getLanguageReducer };
