import React, {useEffect, useState} from "react";
import {Box, Dialog, DialogContent, Pagination} from "@mui/material";
import {styled} from "@mui/material/styles";
import MapComponent from "../Map";
import {Accordion} from "..";
import DialogHeader from "./DialogHeader";
import {DialogProps} from "../../interfaces";
import {useSelector} from "react-redux";
import {fetchSchoolList} from "../../services/school";
import {fetchStreetList} from "../../services/street";
import {getText} from "../../utils";

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    width: "80%",
    height: "calc(100% - 128px)",
    maxWidth: "80%",
    border: "2px solid #01382E",
  },
}));

/**
 * Renders a custom dialog component.
 *
 * @param name - The name of the dialog.
 * @param treeCount - The number of trees.
 * @param zoneId - The zone ID.
 * @param isOpen - Whether the dialog is open.
 * @param onClose - Callback function for closing the dialog.
 * @return The rendered custom dialog component.
 */
export const CustomDialog = ({ name, treeCount, zoneId, isOpen, onClose }: DialogProps) => {

  const plantMarkerDetails = useSelector((state: any) => state.markers);

  const schoolList = useSelector((state: any) => state.schools);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [accordianDetails, setAccordianDetails] = useState<any>([]);

  /**
   * Closes the current page, triggers the onClose function, and resets the accordion details.
   */
  const handleClose = () => {
    setCurrentPage(1);
    onClose();
    setAccordianDetails([])
  }

  const streetList = useSelector((state: any) => state.streets);

  useEffect(() => {
    setAccordianDetails(schoolList);
    setLoading(false);
  }, [schoolList]);

  /**
   * Handles the change event for the given page number.
   *
   * @param event - the event object
   * @param newPage - the new page number
   */
  const handleChange = (event: any, newPage: number) => {
    if (accordianDetails?.schools) {
      setLoading(true);
      fetchSchoolList(zoneId, newPage)
        .then((result: any) => {
          setAccordianDetails(result.data);
          setCurrentPage(newPage)
          setLoading(false);
        }
        )
        .catch((error) => {
          console.log(error);
        })
    } else {
      setLoading(true);
      fetchStreetList(zoneId, newPage)
        .then((result: any) => {
          setAccordianDetails(result.data);
          setCurrentPage(newPage);
          setLoading(false);
        }
        )
        .catch((error) => {
          console.log(error);
        })
    }
  }

  /**
   * Handles the click event on the filter icon.
   *
   * @param event - The click event object.
   * @return This function does not return a value.
   */
  const handleFilterIconClick = (event: any) => {
    event.stopPropagation();
    setCurrentPage(1);
    setAccordianDetails(() =>
      accordianDetails?.schools ? streetList : schoolList
    );
  };
  const language = useSelector((state:any)=>state.language);
const staticTexts = getText(language);
  return (
    <StyledDialog open={isOpen} onClose={handleClose}>
      <DialogHeader zone={name} totalTrees={treeCount} tag={accordianDetails?.schools ? staticTexts['schools'] : staticTexts['streets']} dataCount={accordianDetails?.totalCount} />
      <DialogContent sx={{ padding: "0px" }}>

        <Box padding="24px" >
          <MapComponent height="268px" markers={plantMarkerDetails?.data} />
        </Box>

        <Accordion
          currentPage={currentPage}
          loading={loading}
          type={accordianDetails?.schools ? staticTexts['schools'] : staticTexts['streets']}
          details={accordianDetails?.schools ? accordianDetails?.schools : accordianDetails?.streets}
          handleFilterIconClick={handleFilterIconClick}
        />

      </DialogContent>
      <Box display={'flex'} justifyContent={'center'}>
        {accordianDetails?.totalCount !== 0 && <Pagination page={currentPage} onChange={handleChange} count={(accordianDetails?.totalCount > 20) ? Number(Math.ceil(accordianDetails?.totalCount / 20)) : 1} />}
      </Box>
    </StyledDialog>
  );
};

CustomDialog.defaultProps = {
  isOpen: false,
};

export default CustomDialog;
