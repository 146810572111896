import {GET_ZONES} from "../actions/action";

const initialState: any = [];

/**
 * Reducer function for getting zones.
 *
 * @param state - The current state of the reducer.
 * @param action - The action being dispatched.
 * @return The updated state after the action is applied.
 */
const getZonesReducer = (state = initialState, action: { type: string, payload: [] }) => {
    switch (action.type) {
        case GET_ZONES: {
            return action.payload
        }
        default:
            return state
    }
};

export { getZonesReducer };
