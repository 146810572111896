import { createTheme } from "@mui/material";

const theme = createTheme({
  typography : {
    fontFamily: 'Montserrat'
  },
  components: {
    MuiTable:{
      styleOverrides:{
        root:{
          border : '0.5px solid #B9B9B9',
          borderRadius:'4px',
          overflow : 'hidden'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow:'none',
          "&.Mui-expanded": {
            borderTop: "2px solid #01382E",
            borderBottom: "2px solid #01382E",
            margin: '0 -24px', 
            padding: '0 24px',
            color : "#01382E !important",
            fontWeight : "800 !important",
            fontSize : "16px !important",
            
            "& .MuiTypography-root": { 
                color: "#01382E !important",
                fontWeight: "800 !important",
                fontSize: "16px !important",
              }
          }
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          background: "#01382E",
          color: "#fff",
          borderRadius: "4px",
          textTransform: "none",
          padding: "14px 48px",
          fontWeight: "600",
          fontSize: '16px',
          lineHeight: 'normal',
          "&:hover": {
            background: "#01382E"
          },
          "&:focus": {
            outline: 'none'
          }
        }
      }
    }, 
    MuiCircularProgress:{
      styleOverrides:{
        root:{
          color : "#01382e"
        }
      }
    }   
  },
});

export default theme;
