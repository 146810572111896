import React, {useState} from "react";
import {Box, Menu, MenuItem} from "@mui/material";
import {arrowIcon, hamburger, logo} from "../../assets/images";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {headerMenus, openUrl, urls} from "../../constants";
import './style.scss';
import {useDispatch, useSelector} from "react-redux";
import {GET_LANGUAGE} from "../../redux/actions/action";
import {getText} from "../../utils";

/**
 * Renders the header component.
 *
 * @return The rendered header component.
 */
const Header = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getText(language);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();

  /**
   * Handles the button click event.
   */
  const handleButtonClick = () => {
    dispatch({ type: GET_LANGUAGE, payload: language === 'en' ? 'kn' : 'en' });
  }
  const open = Boolean(anchorEl);

  /**
   * Closes the menu by setting the anchor element to null.
   */
  const closeMenu = () => {
    setAnchorEl(null);
  };

  /**
   * Navigates to the specified URL and closes the menu.
   *
   * @param url - The URL to navigate to.
   */
  const navigateRoute = (url: string) => {
    navigate(url);
    closeMenu();
  };

  /**
   * Handles the click event on the image element.
   *
   * @param event - The click event.
   * @return This function does not return anything.
   */
  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the click event on the menu.
   *
   * @param menu - The selected menu item.
   */
  const handleMenuClick = (menu: string) => {
    switch (menu) {
      case 'Check My Tree':
        return navigateRoute("/join-us");
      case 'Sahaaya':
        return openUrl(urls.sahaaya);
      case 'BBMP':
        return openUrl(urls.bbmp);
      default:
        return navigateRoute("/join-us");
    }
  };

  return (
    <Box id="header" className="web-header">

      {/* Logo */}
      <Box className="logo-container cursor-pointer">
        <img className="logo" src={logo} alt="logo" onClick={() => navigate("/")} />
      </Box>

      {/* Menu options for web view */}
      <Box className="nav-options">
        <Box className='nav-options-menu' display="flex" alignItems='center' justifyContent='center'>
          <Link to="/join-us">{staticTexts["checkMyTree"]}</Link>
          <img
            className={`arrow-icon cursor-pointer ${pathname === "/join-us" ? "visible" : "hidden"}`}
            src={arrowIcon}
            alt="arrow"
            onClick={() => navigate("/")}
          />
        </Box>
        <Box className="nav-options-menu" display="flex" alignItems='center' justifyContent='center'>
          <a href="https://nammabengaluru.org.in/login" target="#_blank">
            {staticTexts['sahaya']}
          </a>
        </Box>
        <Box className="nav-options-menu" display="flex" alignItems='center' justifyContent='center'>
          <a className='nav-options-menu' href="https://bbmp.gov.in/home" target="#_blank">
          {staticTexts['bbmp']}

          </a>
        </Box>
        <Box className="nav-options-menu cursor-pointer" display="flex" width="60px" alignItems='center' justifyContent='center' onClick={handleButtonClick}>
          {language === 'en' ? 'ಕನ್ನಡ' : 'English'}
        </Box>

      </Box>
      {/* Hamburger and menus for mobile view */}
      <img
        className="hamburger-menu"
        src={hamburger}
        alt="hamburger-icon"
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          className: "custom-paper",
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* menu items listing */}
        {headerMenus.map((menu, index) => (
          <MenuItem key={index} onClick={() => handleMenuClick(menu)}>
            <p>{menu}</p>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default Header;
