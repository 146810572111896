import React from "react";
import {Box} from "@mui/material";
import "./style.scss";
import {useSelector} from "react-redux";
import {getText} from "../../utils";

/**
 * Renders the Footer component.
 *
 * @return The rendered Footer component.
 */
const Footer = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getText(language);
  return (
    <Box id="footer">
      <p>
        {staticTexts['rightsReserved']}
        <span className={language === 'en' ? 'underline-text' : 'd-none'}>
          Bruhat Bengaluru Mahanagara Palike
        </span>
      </p>
    </Box>
  );
};

export default Footer;
