import {GET_SCHOOL_LIST} from "../actions/action";

const initialState: any = {};

/**
 * Reducer function for getting the school list.
 *
 * @param state - The current state of the application.
 * @param action - The action object containing the type and payload.
 * @param action.type - The type of the action.
 * @param action.payload - The payload data.
 * @returns The updated state with the school list.
 */
const getSchoolListReducer = (state = initialState, action: { type: string, payload: [] }) => {
    switch (action.type) {
        case GET_SCHOOL_LIST: {
            return action.payload
        }
        default:
            return state
    }
};

export { getSchoolListReducer };
