import React, { Fragment } from "react";
import "./style.scss";
import { Box } from "@mui/material";
import { appleStore, googlePlayButton } from "../../assets/images";

import {
  checkStringLength,
  getText,
  openAppleStore,
  openPlayStore,
} from "../../utils";
import CustomTable from "../../components/Table";
import { Label, TopStudents } from "../../components";
import { useSelector } from "react-redux";

/**
 * Renders the JoinUs component.
 */
const JoinUs = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getText(language);
  const thirtyDaysStatitics = useSelector(
    (state: any) => state.thirtyDaysStatistics
  );
  const ninetyDaysStatitics = useSelector(
    (state: any) => state.ninetyDaysStatistics
  );
  const rows = [
    {
      period: staticTexts["studentFeedback"],
      last30days: thirtyDaysStatitics?.totalFeedback,
      last90days: ninetyDaysStatitics?.totalFeedback,
    },
    {
      period: staticTexts["positive"],
      last30days: thirtyDaysStatitics?.positive,
      last90days: ninetyDaysStatitics?.positive,
    },
    {
      period: staticTexts["negative"],
      last30days: thirtyDaysStatitics?.negative,
      last90days: ninetyDaysStatitics?.negative,
    },
    {
      period: staticTexts["resolved"],
      last30days: thirtyDaysStatitics?.resolved,
      last90days: ninetyDaysStatitics?.resolved,
    },
    {
      period: `${staticTexts["resolved"]}(%)`,
      last30days: thirtyDaysStatitics?.resolvedPercentage
        ? `${thirtyDaysStatitics?.resolvedPercentage}%`
        : "0%",
      last90days: ninetyDaysStatitics?.resolvedPercentage
        ? `${ninetyDaysStatitics?.resolvedPercentage}%`
        : "0%",
    },
  ];

  const columns = [
    {
      id: "period",
      label: staticTexts["period"],
    },
    {
      id: "last30days",
      label: staticTexts["last30days"],
    },
    {
      id: "last90days",
      label: staticTexts["last90days"],
    },
  ];
  const toppers = useSelector((stats: any) => stats.toppers);

  const handleClick = () => {
    const apkUrl = process.env.PUBLIC_URL + "/Hasiru-Rakshaka_v1.0.apk";

    const link = document.createElement("a");
    link.href = apkUrl;
    link.download = "Hasiru-Rakshaka_v1.0.apk";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box id="join-us">
      <Box className="join-us-content-wrapper">
        <Box className="join-us-text-and-buttons-container">
          <p className="join-us-content-text fw-600 text-white font-lg-1">
            {staticTexts["joinAndSupport"]}
          </p>
          <p className="join-us-content-subtext fw-800 text-white font-lg mb-2">
            {staticTexts["hasiruRakshaka"]}
          </p>
          <p
            onClick={handleClick}
            style={{ cursor: "pointer" }}
            className="join-us-content-text fw-600 font-lg-1 bg-transparent text-white hover:text-neutral-950"
          >
            {staticTexts["downloadNow"]}
          </p>

          <Box
            className="button-container"
            display="flex"
            gap="16px"
            marginTop="2rem"
          >
            <img
              src={appleStore}
              alt="apple-store-btn"
              onClick={openAppleStore}
            />
            <img
              src={googlePlayButton}
              alt="google-play-store"
              onClick={openPlayStore}
            />
          </Box>
        </Box>

        <Box
          className="leaderboard-main-wrapper"
          marginTop="4rem"
          marginLeft="10%"
        >
          <Box
            className="app-statistics-container"
            width="500px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Box
              width="100%"
              className="app-statistics fw-700 text-white font-lg-1 d-flex justify-center items-center"
              marginBottom="1rem"
            >
              <p>{staticTexts["appStatistics"]}</p>
            </Box>
            <CustomTable rows={rows} columns={columns} />
          </Box>

          <Box
            className="leaderboard-container"
            width="500px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {toppers?.topContractors && toppers.topContractors.length > 0 && (
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                marginTop="4rem"
              >
                <Box
                  className="fw-700 text-white font-lg-1 d-flex justify-center items-center"
                  marginBottom="3rem"
                >
                  <p>{staticTexts["leaderBoard"]}</p>
                </Box>

                <TopStudents />
              </Box>
            )}
            <Box className="top-5-students" width="100%" marginTop="12rem">
              {toppers?.topStudents && toppers.topStudents.length > 0 && (
                <Fragment>
                  <Box className="fw-700 text-white font-lg-1 d-flex justify-center items-center">
                    <p>{staticTexts["topStudents"]}</p>
                  </Box>
                  {toppers?.topStudents.map((item: any, index: number) => (
                    <Label
                      key={index}
                      index={index}
                      crowned={index === 0 ? true : false}
                      name={item?.name}
                      schoolName={checkStringLength(item?.schoolname)}
                    />
                  ))}
                </Fragment>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default JoinUs;
