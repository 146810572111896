import {httpRequest} from "../network"

/**
 * Fetches statistics of a specific type for a given number of days.
 *
 * @param type - The type of statistics to fetch.
 * @param lastDays - The number of days to include in the statistics.
 * @return A promise that resolves with the fetched statistics data.
 */
const fetchStats = (type: string,lastDays:number) => new Promise((resolve, reject) => {
    httpRequest('GET', `public/statistics?type=${type}&lastDays=${lastDays}`)
        .then((data) => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
});

/**
 * Fetches the list of toppers from the server.
 *
 * @return A promise that resolves with the list of toppers.
 */
const fetchToppers = () => new Promise((resolve, reject) => {
    httpRequest('GET', `public/statistics/toppers-list`)
        .then((data) => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
});

export { fetchStats,fetchToppers };
