import React from "react";
import {Box, Button} from "@mui/material";
import "./style.scss";
import {main} from "../../assets/images";
import {MainProps} from "../../interfaces";
import {getText} from "../../utils";
import {useSelector} from "react-redux";

/**
 * Renders the main component.
 *
 * @param handleClick - The click handler function.
 * @return The rendered main component.
 */
const Main = ({handleClick} : MainProps) => {
  const language = useSelector((state:any)=>state.language);
   const staticTexts = getText(language);
  return (
    <Box id="main-container" position="relative">
      {/* image used as background */}
      <img className="main-container-image" src={main} alt="main" />

      {/* Texts and Buttons */}
      <Box className="text-container fw-700">
        <p className="welcome-text font-lg mb-12" >{staticTexts["welcome"]}</p>
        <p className="sub-text font-sm fw-500 mt-1 mb-12">
          {staticTexts["description1"]}
        </p>
        <p className="sub-text font-sm fw-500 mt-1 mb-12">
          {staticTexts["description2"]}
        </p>
        <p className="sub-text font-sm fw-500 mt-1">
          {staticTexts["description3"]}
        </p>
        <Box className="statistic-button-container">
          <Button onClick={handleClick}>{staticTexts['statistics']}</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Main;
