import React, {Fragment} from "react";
import {Box} from "@mui/material";
import CustomTable from "../../components/Table";
import {TopStudents} from "../../components";
import {columns, rows, staticTexts} from "../../constants";
import "./style.scss";

/**
 * Renders the Statistics component.
 */
const Statistics = () => {
  return (
    <Fragment>
      <Box className="statistic-wrapper">
        <Box className="statistic-table-container">
          <Box className="statistic-table-container-text">{staticTexts['statistics']}</Box>
          <CustomTable rows={rows} columns={columns} />
        </Box>

        <Box className="leaderboard-wrapper-main">
          <Box className="leaderboard-container">
            <Box className="leaderboard-text">
              <p>{staticTexts['leaderboard']}</p>
            </Box>
            <TopStudents />
          </Box>

          <Box className="top-students-wrapper-main">
            <Box className="top-students-text">
              <p>{staticTexts['leaderboard']}</p>
            </Box>
            
          </Box>

          <Box className="statistic-table-container-mobile">
            <Box className="statistic-table-mobile">{staticTexts['statistics']}</Box>
            <CustomTable rows={rows} columns={columns} />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Statistics;
