import {httpRequest} from "../network"

/**
 * Fetches the list of streets for a given zone.
 *
 * @param zoneId - The ID of the zone.
 * @param page - The page number for pagination. Defaults to 1 if not provided.
 * @return A promise that resolves with the list of streets.
 */
const fetchStreetList = (zoneId: string,page?:number) => new Promise((resolve, reject) => {
    httpRequest('GET', `public/street?page=${page ? page : 1}&limit=20&zone=[${zoneId}]`)
        .then((data) => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
});

export { fetchStreetList };
