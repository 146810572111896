import {FC} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import TableChartIcon from "@mui/icons-material/TableChart";
import {StyledTableCell, StyledTableRow} from "./Custom";
import {ColumnProps, TableProps} from "../../interfaces";

/**
 * Renders a custom table component.
 *
 * @param props - The props for the component.
 * @param columns - The columns configuration for the table.
 * @param rows - The data rows for the table.
 * @param height - The height of the table.
 * @param errorMessage - The error message to display when there is no data.
 * @return The rendered custom table component.
 */
const CustomTable: FC<TableProps> = ({
  columns,
  rows,
  height,
  errorMessage = "Add some data to see",
}) => {

  /**
   * Truncates a string value if its length exceeds a maximum value.
   *
   * @param value - The string value to truncate.
   */
  const truncateValue = (value: string) => {
    const maxLength = 30;
    if (value?.length > maxLength) {
      return value.substring(0, maxLength) + "...";
    }

    return value;
  };

  /**
   * Renders a table cell with the specified value, column, and index.
   *
   * @param value - The value to be rendered in the cell.
   * @param column - The column props for the cell.
   * @param index - The index of the cell in the table.
   */
  const renderTableCell = (value: any, column: ColumnProps, index: number) => {
    return (
      <TableCell
        key={column.id}
        align={
          column.id === "period" && (index === 1 || index === 2)
            ? "right"
            : column.id === "period"
            ? "left"
            : "center"
        }
        sx={{
          fontWeight: index === 4 ? "700" : "400",
          padding: "10px 12px",
          borderRadius:
            (index === 4 && column.id) === "period"
              ? "0px 0px 0px 4px"
              : (index === 4 && column.id) === "last90days"
              ? "0px 0px 4px 0px"
              : "0px",
        }}
      >
        {column.format && typeof value === "number"
          ? column.format(value)
          : truncateValue(value)}
      </TableCell>
    );
  };

  return (
    <>
      <TableContainer
        sx={{
          height: height,
          width: "100%%",
          maxWidth: "500px",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column: any, index: number) => (
                <StyledTableCell
                  sx={{ fontWeight: "bold" }}
                  key={index}
                  align="center"
                  style={{ whiteSpace: "nowrap", minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          
          <TableBody>
            {rows.map((row: any, index: number) => {
              return (
                <StyledTableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#f0f0f0" : "#f5f5f5",
                  }}
                >
                  {columns.map((column: ColumnProps) => {
                    const value = row[column.id];
                    return renderTableCell(value, column, index);
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>

        {!rows?.length && (
          <div className="no-data">
            <TableChartIcon fontSize="large" className="error-icon" />
            <Typography className="no-data-title">No data to show!</Typography>
            <Typography className="no-data-sub-title">{errorMessage}</Typography>
          </div>
        )}

      </TableContainer>
    </>
  );
};

export default CustomTable;
