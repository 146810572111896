import {GET_STREET_LIST} from "../actions/action";

const initialState: any = {};

/**
 * Reducer function for getting a list of streets.
 *
 * @param state - The current state of the application.
 * @param action - The action object containing the type and payload.
 * @param action.type - The type of the action.
 * @param action.payload - The payload of the action.
 * @returns The new state after the action has been applied.
 */
const getStreetListReducer = (state = initialState, action: { type: string, payload: [] }) => {
    switch (action.type) {
        case GET_STREET_LIST: {
            return action.payload
        }
        default:
            return state
    }
}

export { getStreetListReducer };
