import {httpRequest} from "../network"

/**
 * Retrieves the location of plants in a specific zone.
 *
 * @param zoneId - The ID of the zone.
 * @return A promise that resolves with the data containing the plant locations, or rejects with an error.
 */
const getPlantsLocation = (zoneId: string) => new Promise((resolve, reject) => {
    httpRequest('GET', `public/plant-location?page=1&limit=100000&zone=[${zoneId}]`)
        .then((data) => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
});

export { getPlantsLocation };
