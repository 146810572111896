import axios from 'axios';

axios.defaults.responseType = 'json';

// TODO: uncomment this after updating .env on live.
axios.defaults.baseURL  = process.env.REACT_APP_BASEURL;

/**
 * Sends an HTTP request to the specified URL using the specified method.
 *
 * @param method - The HTTP method to use for the request. Must be one of 'GET', 'POST', 'PUT', 'DELETE', or 'OPTION'.
 * @param url - The URL to send the request to.
 * @param data - The data to send with the request. Default is an empty object.
 * @param params - The parameters to send with the request. Default is an empty object.
 * @return A promise that resolves with the response data if the request is successful, or rejects with an error message.
 */
function httpRequest<T>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTION',
    url: string,
    data = {},
    params = {}
) {
    return new Promise<T>((resolve, reject) => {
        axios({
            method,
            url,
            data,
            params,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token') || ""}`,
                "Content-Type": "application/json"
            }
        })
            .then((response: any) => {
                if (String(response.status).match(/20/gi)) {
                    resolve(response.data);
                } else {
                    reject("Something went wrong");
                }
            })
            .catch((err: any) => {
                console.log('inside catch', err.response);
                reject(err);
            })
    });
}

export { httpRequest }
